@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body{
  font-family: "Urbanist",sans-serif;
}

.container{
  max-width: 1170px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}

section{
  padding: 50px 0;
}

.slanting-div-l{
  width: calc(50% - 40px);
  height: 400px;
  background: #ED6B06;
}

.slanting-div-r{
  width: calc(50% + 40px);
  height: 400px;
  background: #812F8F;
  border-left: 80px solid #ED6B06;
  border-bottom: 400px solid transparent;
  box-sizing: border-box;
}